import axios from 'axios';
import { Notification } from 'element-ui';
import isEmpty from 'loadsh/isEmpty';
import { getLocal } from './storage';
import { DEVICE_FINGER } from './finger';

const baseDomain = window.location.origin;

export const BASE_DOMAIN = baseDomain;
export const ContentTypeJson = 'application/json;charset=utf-8';
export const ContentTypeJsonUrlencoded = 'application/x-www-form-urlencoded';
export const ContentTypeJsonForm = 'multipart/form-data';

let REAL_IP = '';
const getIp = async () => {
  try {
    if (REAL_IP) return REAL_IP;
    const baseUrl = process.env.NODE_ENV === 'development' ? '/web' : window.location.origin;
    const { ip } = await fetch(`${baseUrl}/realip`).then((res) => res.json());
    REAL_IP = ip;
    return REAL_IP;
  } catch (error) {
    return '0.0.0.0';
  }
};

const defaultError = '抱歉，网络信号异常，请检查网络后重试';
const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
  606: '用户登录过期，请重新登录。',
  611: '用户登录过期，请重新登录。',
  958: '您今日接受邀请的次数达到上限',
};

const authorization = (noAuth) => {
  const AuthorizationToken =
    !noAuth && getLocal('ACCESS_TOKEN') ? `${getLocal('TOKEN_TYPE')} ${getLocal('ACCESS_TOKEN')}` : 'Basic QURNSU5fUEM6QWJjZEAx';
  return AuthorizationToken;
};

let $notify = null;

const initInstance = (instance) => {
  // 添加请求拦截器
  instance.interceptors.request.use(async (config) => {
    config.headers['x-api-ip'] = await getIp();
    if (window.$SYSTEM_CODE) {
      config.headers['x-api-channel'] = window.$SYSTEM_CODE;
    }
    if (!config.params) config.params = {};
    config.params._v = Date.now();

    // 去除运营平台部门D0000000参数
    if (config.data?.customs && window.$SYSTEM_CODE === 'ADMIN_PC') {
      const index = config.data.customs.findIndex((item) => item.custom === 'belowView');
      index > -1 && config.data.customs[index].value === 'D0000000' && config.data.customs.splice(index, 1);
    }

    if (typeof config.data === 'object' && config.headers['Content-Type'] === ContentTypeJson) {
      config.data = JSON.stringify(config.data);
    } else if (typeof config.data === 'object' && config.headers['Content-Type'] === ContentTypeJsonUrlencoded) {
      config.data = Object.keys(config.data)
        .map((attr) => `${attr}=${config.data[attr]}`)
        .join('&');
    } else if (typeof config.data === 'object' && config.headers['Content-Type'] === ContentTypeJsonForm) {
      const formData = new FormData();
      Object.keys(config.data).forEach((k) => formData.append(k, config.data[k]));
      config.data = formData;
    }

    config.headers.Authorization = authorization(config.params.noAuth);
    return config;
  });

  /**
   * 异常处理程序
   */
  const errorHandler = (response) => {
    if (response && response.status) {
      const errorText = codeMessage[response.status] || response.msg || response.message;
      const error = new Error(errorText);

      const res = response.data;
      error.res = res || {};

      return error;
    }
    if (!response) return new Error(defaultError);
  };

  // 添加响应拦截器
  instance.interceptors.response.use(
    (response) => {
      const error = errorHandler(response);
      const { res } = error;

      if (response.config.responseType === 'blob') {
        return res;
      }

      if (res && !isEmpty(res)) {
        if (typeof res !== 'object') return res;
        res.code = res.code || res.resultCode;

        if (+res.code === 200 || +res.status === 0 || response.data.ip) {
          return res;
        }
        if (+res.code === 401 || +res.code === 2022) {
          localStorage.clear();
          sessionStorage.clear();
          const { origin, pathname } = window.location;
          window.location.href = window.__POWERED_BY_QIANKUN__ ? '/' : `${origin}${pathname}#/login`;
        }
        res.status = res.code;
        res.statusText = res.msg || res.message;
        const error = errorHandler(res);

        if (response.config.hiddenMessage) return Promise.reject(response);
        if (!$notify) {
          $notify = Notification.error({
            title: `操作失败${error.status ? `[${error.status}]` : ''}`,
            message: res.statusText || error.message,
            position: 'bottom-right',
            onClose: () => {
              $notify = null;
            },
          });
        }
      }

      throw new Error(JSON.stringify(res));
    },
    (error) => {
      const { config } = error;
      if (config.hiddenMessage) {
        return Promise.reject(error.response);
      }
      if (!error.response) {
        if ($notify) return;
        $notify = Notification.error({
          message: '请求超时，请稍后再试！',
          position: 'bottom-right',
          onClose: () => {
            $notify = null;
          },
        });
      } else {
        const err = errorHandler(error.response);
        if ($notify) return;
        $notify = Notification.error({
          title: `请求错误${error.response.status ? `[${error.response.status}]` : ''}`,
          message: err.message,
          position: 'bottom-right',
          onClose: () => {
            $notify = null;
          },
        });
      }
      throw new Error(defaultError);
    },
  );

  return instance;
};

const cache = new Map();
const getAxios = (config = {}) => {
  const key = JSON.stringify(config);
  if (cache.get(key)) return cache.get(key);
  const headers = Object.assign(
    {
      'Content-Type': ContentTypeJson,
      'x-api-device': DEVICE_FINGER,
    },
    config.headers,
  );
  Reflect.deleteProperty(config, 'headers');
  const request = initInstance(
    axios.create({
      baseURL: process.env.VUE_APP_AXIOS_BASE_URL,
      timeout: 20000,
      withCredentials: true,
      data: {},
      headers,
      ...config,
    }),
  );
  cache.set(key, request);
  return request;
};
export default getAxios;
