import Vue from 'vue';
import {
  Button,
  Loading,
  Table,
  TableColumn,
  Form,
  FormItem,
  DatePicker,
  RadioButton,
  RadioGroup,
  Pagination,
  Select,
  Option,
  Input,
  Checkbox,
  CheckboxGroup,
  TimePicker,
  Divider,
  Dialog,
  Notification,
} from 'element-ui';
import { MIXIN_GLOBAL_CONSTANT } from '@common/mixin/mixins';
import VDPhoto from 'vue-dark-photo';
import store from './store';
import router from './router';
import App from './App.vue';
import 'vue-dark-photo/lib/vue-dark-photo.css';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/styles/global.scss';
import '@/common/assets/icon-font/iconfont.css';
import '@entry/assets/font/font.css';

Vue.use(VDPhoto);
Vue.use(Button);
Vue.use(Loading);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(RadioButton);
Vue.use(RadioGroup);
Vue.use(Pagination);
Vue.use(Select);
Vue.use(Option);
Vue.use(Input);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(TimePicker);
Vue.use(Divider);
Vue.use(Dialog);

Vue.prototype.$notify = Notification;
Vue.prototype.$SYSTEM = 'ENTRY';

// 注册全局常量
MIXIN_GLOBAL_CONSTANT();

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
