export const HEADER_ACTIVE_MENU = '$$OP_ACTIVE_HEADER';

export const CUSTOMER_CLASSIFY = [
  {
    value: '1',
    label: 'I类客户',
    remark: 'I类客户为：联帮医疗的客户',
  },
  {
    value: '2',
    label: 'II类客户',
    remark: ' Ⅱ类客户为：非联帮医疗客户，主要为定位是医疗机构、也可以是其他民用单位（如：含有医用气体系统的客户）',
  },
  {
    value: '3',
    label: 'III类客户',
    remark: 'Ⅲ类客户为：其他医疗产品生产厂家或代理商、供应商、经销商等',
  },
];

export const CUSTOMER_TYPE = [
  {
    value: 'HOSPITAL',
    label: '医院',
  },
  {
    value: 'ENTERPRISES',
    label: '企事业单位',
  },
  {
    value: 'HOTEL',
    label: '酒店',
  },
];

export const GROUP_GRADE = [
  {
    value: 'REGIONAL',
    label: '大区级',
  },
  {
    value: 'PROVINCIAL',
    label: '省级',
  },
  {
    value: 'CITY',
    label: '市级',
  },
  {
    value: 'DISTRICT',
    label: '区县级',
  },
  {
    value: 'CUSTOMER',
    label: '客户级',
  },
];

export const KEEP_FREQUENCY = [
  {
    value: 'ONE_MONTH',
    label: '每月',
  },
  {
    value: 'TWO_MONTH',
    label: '每2月',
  },
  {
    value: 'THREE_MONTH',
    label: '每3月',
  },
  {
    value: 'HALF_YEAR',
    label: '每半年',
  },
  {
    value: 'ONE_YEAR',
    label: '每一年',
  },
  {
    value: 'TWO_YEAR',
    label: '每两年',
  },
  {
    value: 'THREE_YEAR',
    label: '每三年',
  },
  {
    value: 'FOUR_YEAR',
    label: '每四年',
  },
];

export const DEVICE_TYPE = [
  {
    value: 'MEDICAL',
    label: '医用',
  },
  {
    value: 'CIVIL',
    label: '民用',
  },
];

export const REGISTER_TYPE = [
  {
    value: 'REGISTER',
    label: '注册',
  },
  {
    value: 'NONE_REGISTER',
    label: '非注册',
  },
  {
    value: 'RECORD',
    label: '备案',
  },
  {
    value: 'NONE_RECORD',
    label: '非备案',
  },
];

export const MEDICAL_TYPE = [
  {
    value: '1',
    label: 'I类医疗器械',
  },
  {
    value: '2',
    label: 'II类医疗器械',
  },
];

export const BELONG_SYSTEM = [
  {
    value: '1',
    label: '空气供应源',
  },
  {
    value: '2',
    label: '负压供应源',
  },
  {
    value: '3',
    label: '氧气供应源',
  },
  {
    value: '4',
    label: '汇流排',
  },
  {
    value: '5',
    label: '楼层压力',
  },
  {
    value: '6',
    label: '楼层流量',
  },
  {
    value: '7',
    label: '其他',
  },
];

export const DEVICE_STATUS = [
  {
    value: 'INSTALL',
    label: '安装',
  },
  {
    value: 'SERVICING',
    label: '服务中',
  },
  {
    value: 'EXPIRE',
    label: '服务过期',
  },
  {
    value: 'SCRAPPED',
    label: '已报废',
  },
  {
    value: 'DISCARD',
    label: '已废弃',
  },
];

// ONLINE('在线'), OFFLINE('离线'), ABNORMAL('异常'), ALARMING('报警');
export const DEVICE_STATE = [
  {
    value: 'ONLINE',
    label: '在线',
  },
  {
    value: 'OFFLINE',
    label: '离线',
  },
  {
    value: 'ABNORMAL',
    label: '异常',
  },
  {
    value: 'ALARMING',
    label: '报警',
  },
];

export const NOTICE_CHANNEL = [
  {
    value: 'APPLET',
    label: '小程序',
  },
  {
    value: 'SMS',
    label: '短信',
  },
];

export const CHANNEL_PROTOCOL = [
  {
    value: '3',
    label: 'modbus-rtu',
  },
  {
    value: '2',
    label: 'modbus-tcp',
  },
  {
    value: '1',
    label: 'virtual',
  },
];

export const BPS = [
  {
    value: '12',
    label: '1200',
  },
  {
    value: '13',
    label: '2400',
  },
  {
    value: '1',
    label: '4800',
  },
  {
    value: '2',
    label: '9600',
  },
  {
    value: '3',
    label: '14400',
  },
  {
    value: '4',
    label: '19200',
  },
  {
    value: '5',
    label: '38400',
  },
  {
    value: '6',
    label: '56000',
  },
  {
    value: '7',
    label: '57600',
  },
  {
    value: '8',
    label: '115200',
  },
  {
    value: '9',
    label: '128000',
  },
  {
    value: '10',
    label: '187500',
  },
  {
    value: '11',
    label: '2560000',
  },
];

export const CHECK_BIT = [
  {
    value: '1',
    label: '无校验',
  },
  {
    value: '2',
    label: '奇校验',
  },
  {
    value: '3',
    label: '偶校验',
  },
];

export const DATA_BIT = [
  {
    value: '1',
    label: '7',
  },
  {
    value: '2',
    label: '8',
  },
];

export const STOP_BIT = [
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
];

export const TWO_ORDER = [
  {
    value: '1',
    label: 'AB',
  },
  {
    value: '2',
    label: 'BA',
  },
];

export const FOUR_ORDER = [
  {
    value: '1',
    label: 'ABCD',
  },
  {
    value: '2',
    label: 'BADC',
  },
  {
    value: '3',
    label: 'CDAB',
  },
  {
    value: '4',
    label: 'DCBA',
  },
];

export const FLOAT_ORDER = [
  {
    value: '1',
    label: 'ABCD',
  },
  {
    value: '2',
    label: 'BADC',
  },
  {
    value: '3',
    label: 'CDAB',
  },
  {
    value: '4',
    label: 'DCBA',
  },
];

export const ACCESS_TYPE = [
  {
    value: 'RW',
    label: '读写',
  },
  {
    value: 'R',
    label: '只读',
  },
  {
    value: 'W',
    label: '只写',
  },
];

export const DATA_TYPE = [
  {
    value: '2',
    label: 'uint16',
  },
  {
    value: '3',
    label: 'int16',
  },
  {
    value: '4',
    label: 'uint32',
  },
  {
    value: '5',
    label: 'int32',
  },
  {
    value: '6',
    label: 'float',
  },
  {
    value: '1',
    label: 'bool',
  },
  {
    value: '7',
    label: '取位[0]',
  },
  {
    value: '8',
    label: '取位[1]',
  },
  {
    value: '9',
    label: '取位[2]',
  },
  {
    value: '10',
    label: '取位[3]',
  },
  {
    value: '11',
    label: '取位[4]',
  },
  {
    value: '12',
    label: '取位[5]',
  },
  {
    value: '13',
    label: '取位[6]',
  },
  {
    value: '14',
    label: '取位[7]',
  },
  {
    value: '15',
    label: '取位[8]',
  },
  {
    value: '16',
    label: '取位[9]',
  },
  {
    value: '17',
    label: '取位[10]',
  },
  {
    value: '18',
    label: '取位[11]',
  },
  {
    value: '19',
    label: '取位[12]',
  },
  {
    value: '20',
    label: '取位[13]',
  },
  {
    value: '21',
    label: '取位[14]',
  },
  {
    value: '22',
    label: '取位[15]',
  },
];

export const DATA_TAG = [
  {
    value: 'QUALITY',
    label: '指标监控',
  },
  {
    value: 'FAULT',
    label: '故障信息',
  },
  {
    value: 'RUNNING',
    label: '数据统计',
  },
  {
    value: 'ENVIRONMENT',
    label: '环境监控',
  },
  {
    value: 'OTHER',
    label: '其他',
  },
];

export const STORAGE_TYPE = [
  {
    value: '3',
    label: '输入寄存器',
  },
  {
    value: '4',
    label: '输出寄存器',
  },
  {
    value: '1',
    label: '输入继电器',
  },
  {
    value: '2',
    label: '输出线圈',
  },
];

export const ALARM_LEVEL = [
  {
    value: 'WARNING',
    label: '预警',
  },
  {
    value: 'ALARMING',
    label: '报警',
  },
];

export const TRIGGER_TYPE = [
  {
    value: '>',
    label: '大于',
  },
  {
    value: '=',
    label: '等于',
  },
  {
    value: '<',
    label: '小于',
  },
  {
    value: '>=',
    label: '大于或等于',
  },
  {
    value: '<=',
    label: '小于或等于',
  },
];

export const ALARM_TYPE = [
  {
    value: 'SINGLE',
    label: '单次报警',
  },
  {
    value: 'CYCLE',
    label: '周期报警',
  },
];

export const RELIEVE_TYPE = [
  {
    value: '>',
    label: '大于',
  },
  {
    value: '=',
    label: '等于',
  },
  {
    value: '<',
    label: '小于',
  },
  {
    value: '>=',
    label: '大于或等于',
  },
  {
    value: '<=',
    label: '小于或等于',
  },
];

export const QQ_MAP_KEY = 'IJZBZ-NZAEK-JI4JK-AVZ6U-5H26E-4NFXD';
// export const QQ_MAP_KEY = 'RVHBZ-KWECJ-7XZFX-FX5XY-CJX22-I2FIV';

// 处理方式
export const HANDLE_TYPE = [
  {
    value: '1',
    label: '误报',
  },
  {
    value: '2',
    label: '已处理',
  },
];

// 报警状态
export const ALARM_STATUS = [
  {
    value: 'WAITING',
    label: '未处理',
  },
  {
    value: 'RECOVERED',
    label: '已恢复',
  },
  {
    value: 'HANDLED',
    label: '已处理',
  },
];

// 设备状态
// export const DEVICE_STATE = {};
