import Vue from 'vue';
import * as constant from './constant';

export const MIXIN_GLOBAL_CONSTANT = () => {
  Vue.mixin({
    data() {
      return {
        ...constant,
      };
    },
  });
};
