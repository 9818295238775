import getAxios from '../utils/request';

const request = getAxios();

export const updateDevice = (device) => request({
  url: `/manager/device/${device.deviceId}/update`,
  method: 'put',
  data: device,
});

export const pageDevice = (data) => request({
  url: '/manager/device/info/page',
  method: 'post',
  data,
});

export const listDevice = (data) => request({
  url: '/manager/device/info/list',
  method: 'post',
  data,
});

export const addDevice = (data) => request({
  url: '/manager/device/add',
  method: 'post',
  data,
});

export const deviceDetail = (params) => request({
  url: `/manager/device/${params.deviceId}/detail`,
  method: 'get',
  params,
});

export const customerQueryDevice = (params) => request({
  url: '/manager/device/custom/query',
  method: 'get',
  params,
});

export const deleteDevice = (device) => request({
  url: `/manager/device/${device.deviceId}/delete`,
  method: 'delete',
});

export const gatewayIdBindDevice = (gateway, data) => request({
  url: `/manager/device/${gateway.gatewayId}/bind/batch`,
  method: 'post',
  data,
});

export const gatewayIdUnbindDevice = (device, data) => request({
  url: `/manager/device/${device.deviceId}/unbind`,
  method: 'post',
  data,
});

export const deviceUpdateBatch = (data) => request({
  url: '/manager/device/update/batch',
  method: 'put',
  data,
});

export const deviceConfigBatch = (gatewayId, data) => request({
  url: `/manager/device/${gatewayId}/config/batch`,
  method: 'post',
  data,
});

export const deviceParts = (device) => request({
  url: `/manager/device/${device.deviceId}/parts`,
  method: 'get',
});

export const saveDeviceParts = (device, data) => request({
  url: `/manager/device/${device.deviceId}/parts/save`,
  method: 'put',
  data,
});

export const deviceVariables = (device) => request({
  url: `/manager/device/${device.id}/variables`,
  method: 'get',
});

export const saveDeviceVariables = (device, data) => request({
  url: `/manager/device/${device.id}/variables/save`,
  method: 'put',
  data,
});

export const deviceAlarms = (device) => request({
  url: `/manager/device/${device.id}/alarms`,
  method: 'get',
});

export const deviceVariableAlarms = (deviceId) => request({
  url: `/manager/device/alarm/${deviceId}/alarm`,
  method: 'get',
});

export const saveDeviceAlarms = (device, data) => request({
  url: `/manager/device/${device.id}/alarms/save`,
  method: 'put',
  data,
});

export const deviceVariablesDownload = (deviceId) => request({
  url: `/manager/device/${deviceId}/variables/download`,
  method: 'get',
  responseType: 'blob',
});

export const deviceVariableAlarm = (device, variable) => request({
  url: `/manager/device/${device.deviceId}/${variable.id}/alarm`,
  method: 'get',
});

export const saveDeviceVariableAlarm = (device, variable) => request({
  url: `/manager/device/${device.deviceId}/${variable.variableId}/alarm/save`,
  method: 'put',
  data: variable,
});

// 设备实时数据
export const realtimeData = (params) => request({
  url: `/stream/data/${params.deviceId}/real-time/data`,
  method: 'get',
  params,
});

// 当前曲线
export const curveRealData = (params) => request({
  url: `/stream/data/${params.deviceId}/curve/real-data`,
  method: 'get',
  params,
  timeout: 0,
});

// 数据曲线
export const curveData = (params, data) => request({
  url: `/stream/data/${params.deviceId}/curve/data`,
  method: 'post',
  params,
  data,
});

// 数据报表
export const dataReport = (params, data) => request({
  url: `/stream/data/${params.deviceId}/report/data`,
  method: 'post',
  params,
  data,
});

// 设备报警
export const pageDeviceAlaram = (data) => request({
  url: '/manager/device/alarm/info/page',
  method: 'post',
  data,
});

// 处理报警信息
export const handleDeviceAlarm = (data) => request({
  url: `/manager/device/alarm/${data.deviceAlarmId}/handle`,
  method: 'put',
  data,
});

// 导出设备报警
export const downloadAlarmInfo = (data) => request({
  url: '/manager/device/alarm/info/download',
  method: 'post',
  data,
  responseType: 'blob',
});

export const downloadReportData = (params, data) => request({
  url: `/manager/device/${params.deviceId}/report/data/download`,
  method: 'post',
  params,
  data,
  responseType: 'blob',
});

export const downloadCurveData = (params, data) => request({
  url: `/manager/device/${params.deviceId}/curve/data/download`,
  method: 'post',
  params,
  data,
  responseType: 'blob',
});

export const officeList = (params) => request({
  url: '/manager/device/office/info/list',
  method: 'post',
  params,
});
