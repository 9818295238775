import Vuex from 'vuex';
import Vue from 'vue';
import app from './app';
import qiankun from './qiankun';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    qiankun,
  },
});

export default store;
