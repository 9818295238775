import { initGlobalState } from 'qiankun';
// qiankun global state
const globalState = {
  current: '',
  username: '',
};
export const actions = initGlobalState(globalState);

const qiankun = {
  state: () => ({
    globalState,
  }),
  mutations: {
    SET_GLOBAL_STATE(state, globalState) {
      const nvalve = Object.assign(state.globalState, globalState);
      state.globalState = nvalve;
      actions.setGlobalState(nvalve);
    },
  },
  actions: {
    setGlobalState({ commit }, globalState) {
      commit('SET_GLOBAL_STATE', globalState);
    },
  },
  getters: {
    globalState: (state) => state.globalState,
  },
  namespaced: true,
};

export default qiankun;
