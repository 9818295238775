const int16ToHex = (i) => {
  let result = i.toString(16);
  let j = 0;
  while (j + result.length < 4) {
    result = `0${result}`;
    j++;
  }
  return result;
};

const bin2hex = (str) => {
  let result = '';
  for (let i = 0; i < str.length; i++) {
    result += int16ToHex(str.charCodeAt(i));
  }
  return result;
};

export const getFinger = () => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const txt = 'http://www.koal.com/';
  ctx.textBaseline = 'top';
  ctx.font = "14px 'Arial'";
  ctx.textBaseline = 'middle';
  ctx.fillStyle = '#f60';
  ctx.fillRect(125, 1, 62, 20);
  ctx.fillStyle = '#069';
  ctx.fillText(txt, 2, 15);
  ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
  ctx.fillText(txt, 4, 17);

  const b64 = canvas.toDataURL().replace('data:image/png;base64,', '');
  const bin = window.atob(b64);
  const crc = bin2hex(bin.slice(-16, -12));
  return crc;
};

export const DEVICE_FINGER = getFinger();
