import VueRouter from 'vue-router';
import Vue from 'vue';
import { Message } from 'element-ui';
import LayoutMonitor from '@entry/views/monitor/index.vue';
import store from '../store';
import { loginChannel } from '@/common/api/auth';

// 解决导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

export const monitor = {
  path: '/runing/:id',
  name: 'runing',
  component: LayoutMonitor,
  meta: {
    hidden: true,
  },
  children: [
    {
      path: 'realtime',
      name: 'runing-realtime',
      code: 'RUNTIME_MONITOR',
      component: () => import('@entry/views/monitor/RealTime.vue'),
      meta: {
        title: '实时监测',
      },
    },
    {
      path: 'curve',
      name: 'runing-curve',
      code: 'RUNTIME_MONITOR',
      component: () => import('@entry/views/monitor/DataCurve.vue'),
      meta: {
        title: '数据曲线',
      },
    },
    {
      path: 'report',
      name: 'runing-report',
      code: 'RUNTIME_MONITOR',
      component: () => import('@entry/views/monitor/DataReport.vue'),
      meta: {
        title: '数据报表',
      },
    },
    {
      path: 'records',
      name: 'runing-records',
      code: 'RUNTIME_MONITOR',
      component: () => import('@entry/views/monitor/AlarmRecords.vue'),
      meta: {
        title: '报警记录',
      },
    },
    {
      path: 'alarm',
      name: 'monitor-alarm-view',
      code: 'ALARM_MONITOR',
      component: () => import('@entry/views/monitor/AlarmRecordsEcharts.vue'),
      meta: {
        title: '查看报警监控',
        hidden: true,
      },
    },
  ],
};

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@entry/views/Login.vue'),
  },
  {
    path: '/selection',
    name: 'selection',
    component: () => import('@entry/views/Selection.vue'),
  },
  {
    path: '/zcyl/:system',
    name: 'zcyl',
    component: () => import('@entry/views/MicroApp.vue'),
  },
  monitor,
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

const WHITE_LIST = ['login'];

const controlRoute = (channels) => {
  if (channels.includes('ADMIN_PC') && channels.includes('SCREEN_PC')) {
    return '/selection';
  }
  if (channels.includes('ADMIN_PC')) {
    return '/zcyl/operation/#/';
  }
  if (channels.includes('SCREEN_PC')) {
    return '/zcyl/monitor/#/';
  }
};

const getLoginChannel = async () => {
  const { channels } = store.state.app;
  if (channels?.length) return channels;
  try {
    const { data } = await loginChannel();
    store.commit('app/SET_CHANNELS', data);
    return data || [];
  } catch (error) {
    return [];
  }
};

let redirectUrl = '';
let $notify = null;

router.beforeEach(async (to, from, next) => {
  if (store.state.app.isLogin) {
    // 如果进入selection 判断是否需要重定向
    if (!redirectUrl) {
      const data = await getLoginChannel();
      redirectUrl = controlRoute(data);
      if (redirectUrl) {
        ['selection', 'login'].includes(to.name) ? next({ path: redirectUrl, replace: true }) : next();
      } else {
        if (!$notify) {
          $notify = Message.warning({
            message: '暂无权限，请联系管理员授权',
            onClose: () => {
              $notify = null;
            },
          });
        }
        window.localStorage.clear();
        store.commit('app/SET_IS_LOGIN', false);
        next({ path: '/login', replace: true });
      }
    } else if (store.getters['app/isSingle'] && to.name === 'selection') {
      next({ path: redirectUrl, replace: true });
    } else if (to.name === 'login') {
      next({ path: '/selection', replace: true });
    } else {
      next();
    }
  } else {
    WHITE_LIST.includes(to.name) ? next() : next({ path: '/', replace: true });
  }
});

export default router;
