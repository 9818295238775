import { getLocal } from '@/common/utils/storage';

const app = {
  state: () => ({
    isLogin: !!getLocal('ACCESS_TOKEN'),
    userInfo: {
      name: 'admin',
    },
    channels: [],
  }),
  mutations: {
    SET_IS_LOGIN(state, isLogin) {
      state.isLogin = isLogin;
    },
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    SET_CHANNELS(state, channels) {
      state.channels = channels;
    },
  },
  actions: {
    setUserInfo({ commit }, userInfo) {
      commit('SET_USER_INFO', userInfo);
    },
    setRoleList({ commit }, roleList) {
      commit('SET_ROLE_LIST', roleList);
    },
  },
  getters: {
    userInfo: (state) => state.userInfo,
    isLogin: (state) => state.isLogin,
    isSingle: (state) => state.channels.filter((item) => ['ADMIN_PC', 'SCREEN_PC'].includes(item)).length < 2,
  },
  namespaced: true,
};

export default app;
