<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// // import { login } from '@common/api';
// import { mapGetters, mapActions } from 'vuex';

// import { startMicro } from './utils/qiankun';

export default {
  // computed: {
  //   ...mapGetters('qiankun', ['globalState']),
  // },
  // methods: {
  //   ...mapActions('qiankun', ['setGlobalState']),
  // },
  // mounted() {
  //   setTimeout(() => {
  //     this.setGlobalState({ username: 'shenghao' });
  //   }, 5000);
  //   startMicro();
  // },
};
</script>

<style>
#app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

#micro {
  width: 100vw;
  height: 100vh;
}
</style>
