<template>
  <div class="zclb-datetime">
    <span class="zclb-time">{{ time }}</span>
    <span class="zclb-week">{{ week }}</span>
  </div>
</template>

<script>
const moment = require('moment');

export default {
  data() {
    return {
      time: '',
      week: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'][new Date().getDay()],
      timer: '',
    };
  },
  beforeMount() {
    this.systemTime();
  },
  beforeDestroy() {
    this.timer && clearTimeout(this.timer);
  },
  methods: {
    systemTime() {
      this.timer = setTimeout(() => {
        this.time = moment().format('YYYY-MM-DD HH:mm:ss');
        this.systemTime();
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.zclb-datetime {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
