import { OPERATOR } from './constant';
import { isString } from './is';

export const upcaseInitial = (wrod) => (isString(wrod) && wrod.length ? `${wrod.at(0).toUpperCase()}${wrod.slice(1)}` : wrod);

/* eslint-disable max-len */
export const getCondition = (columns, value, operator = OPERATOR.LIKE) => (value ? columns.map((column) => ({ column, operator, value })) : []);

export const getFiltersCondition = (tableFilter, operator = OPERATOR.IN) => (tableFilter
  ? Object.keys(tableFilter)
    .filter((key) => tableFilter[key] && tableFilter[key].length)
    .map((column) => ({
      column,
      operator,
      value: tableFilter[column],
    }))
  : []);

export const getCustomsOr = (fileds, value, operator = OPERATOR.LIKE) => (value
  ? [
    {
      custom: [fileds[0], ...fileds.slice(1).map(upcaseInitial)].join('Or'),
      value,
      operator,
    },
  ]
  : []);
