import getAxios from '../utils/request';

const request = getAxios();

export const authPermit = () => request({
  url: '/system/auth/permit',
  method: 'get',
});

export const loginChannel = () => request({
  url: '/system/user/login/channel',
  method: 'get',
});
