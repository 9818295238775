export const OPERATOR = {
  NONE: 'NONE',
  EQ: 'EQ',
  NE: 'NE',
  GT: 'GT',
  LT: 'LT',
  GTE: 'GTE',
  LTE: 'LTE',
  IN: 'IN',
  NIN: 'NIN',
  LIKE: 'LIKE',
  NLIKE: 'NLIKE',
  NUL: 'NUL',
  NNUL: 'NNUL',
  BETWEEN: 'BETWEEN',
  ALL: 'ALL',
};
