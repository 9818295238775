<template>
  <div class="zclb-operation__monitor">
    <div class="zclb-operation__monitor-header">
      <div class="zclb-operation__monitor-header--device">
        <div class="zclb-operation__monitor-header--hos">{{ currentDevice.customerName }}</div>
        <div class="zclb-operation__monitor-header--select">
          <el-select v-model="currentDeviceId" placeholder="请选择" @change="handlerChange" popper-class="device-selector">
            <el-option v-for="device in deviceList" :key="device.deviceId" :label="device.name" :value="device.deviceId"></el-option>
          </el-select>
        </div>
        <div class="zclb-operation__monitor-header--modal">{{ currentDevice.modelName }}</div>
      </div>
      <div class="zclb-operation__monitor-header--option">
        <div class="zclb-operation__monitor-header--status" :class="currentDevice.state"></div>
        <div v-if="updateTime" class="zclb-operation__monitor-header--update">更新时间：{{ updateTime }}</div>
        <i :class="isFullscreen ? 'el-icon-yl-exit-full' : 'el-icon-yl-full'" @click="fullscreen"></i>
        <i class="el-icon-yl-close" @click="close"></i>
      </div>
    </div>
    <div class="zclb-operation__monitor-tabs">
      <div
        :class="['zclb-operation__monitor-tabs--item', select === d.name ? 'select' : '']"
        v-for="d in displayTabs"
        :key="d.name"
        @click="handlerSelect(d)"
      >
        {{ d.meta.title }}
      </div>
    </div>

    <div class="zclb-operation__monitor-content" v-loading="loading" element-loading-background="rgba(9,54,121, 0.3)">
      <router-view v-if="show && !loading" />
      <div class="zclb-operation__monitor-time">
        <span>当前时间:</span>
        <SystemTime />
      </div>
      <div class="zclb-operation__monitor-logo">
        <img :src="require('@common/assets/images/logo-b.png')" alt="" />
        <span>成都联帮医疗科技股份有限公司</span>
      </div>
    </div>
  </div>
</template>

<script>
import SystemTime from '@features/SystemTime';
import { deviceDetail, listDevice, dataReport, deviceVariables } from '@common/api/device';
import { monitor } from '@entry/router';
import screenfull from 'screenfull';
import { getCondition } from '@/common/utils/params';
import { formatterDateTime } from '@/common/utils/date';

export default {
  name: 'monitor',
  components: {
    SystemTime,
  },
  provide() {
    return {
      monitor: this,
    };
  },
  data() {
    return {
      loading: true,
      detail: {},
      select: 'runing-realtime',
      isFullscreen: false,
      deviceList: [],
      show: true,
      currentDeviceId: this.$route.params.id,
      updateTime: '',
      timer: null,
    };
  },
  async created() {
    this.select = this.$route.name;
    await this.deviceDetail();
    this.listDevice();
    if (screenfull.isEnabled) {
      this.isFullscreen = screenfull.isFullscreen;
      screenfull.on('change', () => {
        this.isFullscreen = screenfull.isFullscreen;
      });
    }
    this.polling(() => {
      Promise.all([this.dataReport()]).catch(() => {
        this.timer && clearTimeout(this.timer);
      });
    });
  },
  beforeDestroy() {
    this.timer && clearTimeout(this.timer);
  },
  computed: {
    displayTabs() {
      return monitor?.children.filter((c) => !c.meta?.hidden) || [];
    },
    currentDevice() {
      return this.deviceList.find((item) => item.deviceId === this.currentDeviceId) || {};
    },
  },

  methods: {
    polling(fn, time = 0) {
      this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        fn();
        this.polling(fn, 30000);
      }, time);
    },
    handlerChange(v) {
      this.show = false;
      this.$router.push({ name: this.select, params: { id: v } });
      this.$nextTick(() => {
        this.show = true;
      });
    },
    async dataReport() {
      try {
        const { id } = this.$route.params;
        const params = {
          deviceId: id,
          current: 1,
          size: 1,
          begin: new Date().getTime() - 86400000,
          end: new Date().getTime(),
          frequency: '',
        };
        const { data: variables } = await deviceVariables({ id });
        const { data } = await dataReport(
          params,
          variables.map((item) => item.id),
        );
        const time = data.records?.[0]?.[0];
        this.updateTime = time ? formatterDateTime(time) : '';
      } catch (error) {
        console.log(error);
      }
    },
    async listDevice() {
      try {
        this.loading = true;
        const params = {};
        params.conditions = getCondition(['customerId'], this.detail.customerId);
        params.customs = [{ custom: 'monitor' }];
        const { data } = await listDevice(params);
        this.deviceList = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    close() {
      window.close();
    },
    fullscreen() {
      if (!screenfull.isEnabled) return;
      this.isFullscreen = !this.isFullscreen;
      screenfull.isFullscreen ? screenfull.exit() : screenfull.request(document.documentElement);
    },
    handlerSelect(d) {
      this.select = d.name;
      this.$router.push({ name: d.name });
    },
    async deviceDetail() {
      try {
        const { id } = this.$route.params;
        const { data } = await deviceDetail({ deviceId: id });
        this.detail = data;
      } catch (error) {
        this.detail = {};
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.zclb-operation__monitor {
  height: 100vh;
  width: 100%;
  min-width: 1200px;
  min-height: 600px;
  background: url('~@entry/assets/images/monitor-bg.jpeg');
  background-size: cover;
  // background-color: #093679;
}

.zclb-operation__monitor-header {
  // background: #00244B;
  display: flex;
  height: pxTo1920VW(70);
}

.zclb-operation__monitor-tabs {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #084187;
  background-color: rgba(#093679, 0.8);
  .zclb-operation__monitor-tabs--item {
    width: 25%;
    height: pxTo1920VW(56);
    font-size: pxTo1920VW(18);
    font-weight: bold;
    color: #11abf2;
    line-height: pxTo1920VW(56);
    text-align: center;
    user-select: none;
    box-sizing: border-box;
    cursor: pointer;

    &.select {
      border-bottom: none;
      color: #fff;
      // border-top: none;
      background-color: linear-gradient(0deg, #001d60 0%, #003775 62%);
      background-image: url('~@entry/assets/images/tab-select.png');
      background-size: cover;
      border-bottom: 3px solid #2abbff;
    }
  }
}

:deep(.zclb-datetime) {
  font-size: pxTo1920VW(18);
  width: pxTo1920VW(230);
  color: #77b6ff;
}

.zclb-operation__monitor-time {
  display: inline-block;
}

.zclb-operation__monitor-content {
  height: calc(100% - #{pxTo1920VW(127)});
  background-color: rgba(#093679, 0.8);
}

.zclb-operation__monitor-header--option {
  padding-right: pxTo1920VW(16);
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .zclb-operation__monitor-header--status {
    margin-right: pxTo1920VW(22);
    width: pxTo1920VW(42);
    height: pxTo1920VW(42);
    background-size: cover;
    &.OFFLINE {
      background-image: url('~@entry/assets/images/offline.png');
    }
    &.ONLINE {
      background-image: url('~@entry/assets/images/normal.png');
    }
    &.ABNORMAL {
      background-image: url('~@entry/assets/images/error.png');
    }
    &.ALARMING {
      background-image: url('~@entry/assets/images/waring.png');
    }
  }

  .zclb-operation__monitor-header--update {
    font-size: pxTo1920VW(18);
    font-weight: 400;
    color: #77b6ff;
  }

  .el-icon-yl-exit-full {
    font-size: pxTo1920VW(28);
  }
  i {
    margin-left: 10px;
    font-size: pxTo1920VW(32);
    font-weight: 500;
    color: #2056af;
    cursor: pointer;

    &:hover {
      color: mix(#2056af, #fff, 90);
    }
  }
}

.zclb-operation__monitor-logo {
  position: absolute;
  bottom: 0px;
  right: pxTo1920VW(26);
  display: flex;
  align-items: center;
  font-size: pxTo1920VW(14);
  line-height: pxTo1920VW(30);
  color: #6fb4ff;
  img {
    height: pxTo1920VW(24);
  }
}

.zclb-operation__monitor-time {
  position: absolute;
  bottom: 0px;
  width: pxTo1920VW(300);
  left: pxTo1920VW(26);
  display: flex;
  align-items: center;
  font-size: pxTo1920VW(14);
  line-height: pxTo1920VW(30);
  color: #6fb4ff;
  .zclb-datetime {
    width: pxTo1920VW(180);
    font-size: pxTo1920VW(14);
  }
}

.zclb-operation__monitor-header--device {
  display: flex;
  width: 100%;
  flex: 1;
  height: 100%;
  align-items: center;
  font-size: pxTo1920VW(22);
  font-weight: 400;
  color: #6fb4ff;

  .zclb-operation__monitor-header--hos {
    padding: 0 pxTo1920VW(34);
  }
  .zclb-operation__monitor-header--select {
    height: 100%;
    :deep(.el-select) {
      height: 100%;
      width: pxTo1920VW(363);
      background: #1b51a1;
      .el-input,
      .el-input__inner {
        height: 100%;
        background: transparent;
        color: #6fb4ff;
        border-radius: 0;
        border: none;
        text-align: center;
        font-size: pxTo1920VW(22);
        font-weight: 500;
      }
      .el-input__icon {
        color: #6fb4ff;
        &::before {
          content: '\e78f';
        }
      }
    }
  }
  .zclb-operation__monitor-header--modal {
    padding-left: pxTo1920VW(64);
  }
}
</style>
